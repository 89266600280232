// IMPORTS

// BOOTSTRAP FOR CUSTOM COMPONENTS
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import url("https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

// FONTS

/* Ostrich Sans 

@font-face {
	font-family: 'Ostrich Sans';
	src: url('/Fonts/ostrich-sans/ostrich-sans-black.eot');
	src: local('☺'), url('/Fonts/ostrich-sans/ostrich-sans-black.woff') format('woff'), url('/Fonts/ostrich-sans/ostrich-sans-black.ttf') format('truetype'), url('/Fonts/ostrich-sans/ostrich-sans-black.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Ostrich Sans';
	src: url('/Fonts/ostrich-sans/ostrich-sans-bold.eot');
	src: local('☺'), url('/Fonts/ostrich-sans/ostrich-sans-bold.woff') format('woff'), url('/Fonts/ostrich-sans/ostrich-sans-bold.ttf') format('truetype'), url('/Fonts/ostrich-sans/ostrich-sans-bold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Ostrich Sans';
	src: url('/Fonts/ostrich-sans/ostrich-sans-regular.eot');
	src: local('☺'), url('/Fonts/ostrich-sans/ostrich-sans-regular.woff') format('woff'), url('/Fonts/ostrich-sans/ostrich-sans-regular.ttf') format('truetype'), url('/Fonts/ostrich-sans/ostrich-sans-regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Ostrich Sans';
	src: url('/Fonts/ostrich-sans/ostrich-sans-regular.eot');
	src: local('☺'), url('/Fonts/ostrich-sans/ostrich-sans-regular.woff') format('woff'), url('/Fonts/ostrich-sans/ostrich-sans-regular.ttf') format('truetype'), url('/Fonts/ostrich-sans/ostrich-sans-regular.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Ostrich Sans';
	src: url('/Fonts/ostrich-sans/ostrich-sans-light.eot');
	src: local('☺'), url('/Fonts/ostrich-sans/ostrich-sans-light.woff') format('woff'), url('/Fonts/ostrich-sans/ostrich-sans-light.ttf') format('truetype'), url('/Fonts/ostrich-sans/ostrich-sans-light.svg') format('svg');
	font-weight: 200;
	font-style: normal;
}

/* Ostrich Sans Dashed 

@font-face {
	font-family: 'Ostrich Sans Dashed';
	src: url('/Fonts/ostrich-sans/ostrich-sans-dashed.eot');
	src: local('☺'), url('/Fonts/ostrich-sans/ostrich-sans-dashed.woff') format('woff'), url('/Fonts/ostrich-sans/ostrich-sans-dashed.ttf') format('truetype'), url('/Fonts/ostrich-sans/ostrich-sans-dashed.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}

/* Ostrich Sans Rounded 

@font-face {
	font-family: 'Ostrich Sans Rounded';
	src: url('/Fonts/ostrich-sans/ostrich-sans-rounded.eot');
	src: local('☺'), url('/Fonts/ostrich-sans/ostrich-sans-rounded.woff') format('woff'), url('/Fonts/ostrich-sans/ostrich-sans-rounded.ttf') format('truetype'), url('/Fonts/ostrich-sans/ostrich-sans-rounded.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}

/* Ostrich Sans Inline 

@font-face {
	font-family: 'Ostrich Sans Inline';
	src: url('/Fonts/ostrich-sans/ostrich-sans-inline-regular.eot');
	src: local('☺'), url('/Fonts/ostrich-sans/ostrich-sans-inline-regular.woff') format('woff'), url('/Fonts/ostrich-sans/ostrich-sans-inline-regular.ttf') format('truetype'), url('/Fonts/ostrich-sans/ostrich-sans-inline-regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Ostrich Sans Inline';
	src: url('/Fonts/ostrich-sans/ostrich-sans-inline-italic.eot');
	src: local('☺'), url('/Fonts/ostrich-sans/ostrich-sans-inline-italic.woff') format('woff'), url('/Fonts/ostrich-sans/ostrich-sans-inline-italic.ttf') format('truetype'), url('/Fonts/ostrich-sans/ostrich-sans-inline-italic.svg') format('svg');
	font-weight: normal;
	font-style: italic;
}

*/

// MAIN

html,
body {
  // font-family: "Calibri" !important; /*"Raleway", sans-serif !important;*/
  margin: 0;
  overflow-x: hidden;
}

* .number,
body .number,
html .number {
  font-family: "Roboto", sans-serif !important;
}

.vs {
  font-size: 0.6rem;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.header-space {
  padding-top: 60px;
}

* {
  box-sizing: border-box;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

// *:not(input, button, a, p) {
//   user-select: none;
//   -moz-user-select: none;
//   -webkit-user-select: none;
// }

.page-width {
  max-width: 1400px;
  width: 100%;
  margin: auto;
}

.flex-space {
  flex: 1 1 auto;
}

.img-cont {
  img {
    width: 100%;
    display: block;
  }
}

a {
  transition-delay: 0s;
  transition-duration: 0s;
  text-decoration: none;
  color: inherit;

  &:hover {
    transition-delay: 0s;
    transition-duration: 0s;
    color: inherit;
  }
}

.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
}

.recharts-tooltip-wrapper {
  z-index: 1000 !important;
}

summary {
  display: flex !important;
  align-items: center !important;
}
summary::after {
  height: 24px;
  width: 24px;
  align-self: center;
  margin-right: 0;
  margin-left: auto;
}
details[disabled] summary::after {
  content: "" !important;
}
@media only screen and (max-width: 768px) {
  summary.small-arrow::after {
    margin-bottom: 9px;
    height: 20px;
    width: 20px;
  }
}

details summary::-webkit-details-marker {
  display: none;
}

summary::after {
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLWRvd24iIGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwb2x5bGluZSBwb2ludHM9IjYgOSAxMiAxNSAxOCA5Ii8+PC9zdmc+");
}
details[open] > summary::after {
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXVwIiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWxpbmUgcG9pbnRzPSIxOCAxNSAxMiA5IDYgMTUiLz48L3N2Zz4=");
}

// BOOTSTRAP OVERRIDES + UPGRADES

.modal {
  --bs-modal-width: 700px;
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.interact {
  cursor: pointer;
}

.interact-hover:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.btn.btn-icon:hover {
  opacity: 0.8;
}

.btn.btn-no-border {
  border-color: transparent;
}

.btn.btn-icon,
.accordion-button,
input:focus,
input:hover {
  outline: none !important;
  box-shadow: none !important;
}

.rounded-lg {
  border-radius: 12px;
}

.card {
  &:hover {
    &.white-tran {
      transition-duration: 0.25s;
      background-color: white !important;
      border-color: white !important;
    }

    &.hover-shadow {
      transition-duration: 0.25s;
      box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.5) !important;
    }
  }
}

.product-description {
  .aplus-v2 {
    .aplus-standard.aplus-module {
      max-width: 100vw;
      display: block;
      min-width: 0;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none !important;
  }
}

.search-dialog:after {
  z-index: -1;
  position: absolute;
  top: 0%;
  transform: translateY(-100%);
  left: 50%;
  margin-left: -10px;
  content: "";
  width: 0;
  height: 0;
  border-bottom: solid 10px #dee2e6;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.gap-x-1 {
  gap: 0 0.25rem !important;
}

.gap-x-2 {
  gap: 0 0.5rem !important;
}

.gap-x-3 {
  gap: 0 1rem !important;
}

.gap-x-4 {
  gap: 0 1.5rem !important;
}

.gap-x-4 {
  gap: 0 3rem !important;
}

.tiny {
  font-size: 0.6em;
}

.flex-fill-even {
  flex: 1 1 0;
}

.h-100 {
  height: 100%;
}

.pg-100 {
  min-height: 100vh;
}

.w-33 {
  width: 33.3%;
}

.w-66 {
  width: 66.6%;
}

.w-content {
  width: fit-content;
}

.opacity-01 {
  opacity: 0.01 !important;
}

.opacity-05 {
  opacity: 0.05 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

// XXL
@media only screen and (max-width: 1400px) {
  .xxl-100 {
    width: 100% !important;
  }

  .xxl-75 {
    width: 75% !important;
  }

  .xxl-50 {
    width: 50% !important;
  }

  .xxl-25 {
    width: 25% !important;
  }
}

// XL
@media only screen and (max-width: 1200px) {
  .xl-100 {
    width: 100% !important;
  }

  .xl-75 {
    width: 75% !important;
  }

  .xl-50 {
    width: 50% !important;
  }

  .xl-25 {
    width: 25% !important;
  }
}

// LG
@media only screen and (max-width: 992px) {
  .lg-100 {
    width: 100% !important;
  }

  .lg-75 {
    width: 75% !important;
  }

  .lg-50 {
    width: 50% !important;
  }

  .lg-25 {
    width: 25% !important;
  }
}

// MD
@media only screen and (max-width: 768px) {
  .md-100 {
    width: 100% !important;
  }

  .md-75 {
    width: 75% !important;
  }

  .md-50 {
    width: 50% !important;
  }

  .md-25 {
    width: 25% !important;
  }
}

// SM
@media only screen and (max-width: 576px) {
  .sm-100 {
    width: 100% !important;
  }

  .sm-75 {
    width: 75% !important;
  }

  .sm-50 {
    width: 50% !important;
  }

  .sm-25 {
    width: 25% !important;
  }
}

// TEMPLATE

// XXL
@media only screen and (max-width: 1400px) {
}

// XL
@media only screen and (max-width: 1200px) {
}

// LG
@media only screen and (max-width: 992px) {
}

// MD
@media only screen and (max-width: 768px) {
}

// SM
@media only screen and (max-width: 576px) {
}

.fs-md-35 {
  font-size: 35px !important;
}

.fs-md-34 {
  font-size: 34px !important;
}

.fs-md-33 {
  font-size: 33px !important;
}

.fs-md-32 {
  font-size: 32px !important;
}

.fs-md-31 {
  font-size: 31px !important;
}

.fs-md-30 {
  font-size: 30px !important;
}

.fs-md-29 {
  font-size: 29px !important;
}

.fs-md-28 {
  font-size: 28px !important;
}

.fs-md-27 {
  font-size: 27px !important;
}

.fs-md-26 {
  font-size: 26px !important;
}

.fs-md-25 {
  font-size: 25px !important;
}

.fs-md-24 {
  font-size: 24px !important;
}

.fs-md-23 {
  font-size: 23px !important;
}

.fs-md-22 {
  font-size: 22px !important;
}

.fs-md-21 {
  font-size: 21px !important;
}

.fs-md-20 {
  font-size: 20px !important;
}

.fs-md-19 {
  font-size: 19px !important;
}

.fs-md-18 {
  font-size: 18px !important;
}

.fs-md-17 {
  font-size: 17px !important;
}

.fs-md-16 {
  font-size: 16px !important;
}

.fs-md-15 {
  font-size: 15px !important;
}

.fs-md-14 {
  font-size: 14px !important;
}

.fs-md-13 {
  font-size: 13px !important;
}

.fs-md-12 {
  font-size: 12px !important;
}

.fs-md-11 {
  font-size: 11px !important;
}

.fs-md-10 {
  font-size: 10px !important;
}

.fs-md-9 {
  font-size: 9px !important;
}

.fs-md-8 {
  font-size: 8px !important;
}

.fs-md-7 {
  font-size: 7px !important;
}

.fs-md-6 {
  font-size: 6px !important;
}

.fs-md-5 {
  font-size: 5px !important;
}

.fs-md-4 {
  font-size: 4px !important;
}

.fs-md-3 {
  font-size: 3px !important;
}

.fs-md-2 {
  font-size: 2px !important;
}

.fs-md-1 {
  font-size: 1px !important;
}

@media only screen and (max-width: 768px) {
  .fs-mb-35 {
    font-size: 35px !important;
  }

  .fs-mb-34 {
    font-size: 34px !important;
  }

  .fs-mb-33 {
    font-size: 33px !important;
  }

  .fs-mb-32 {
    font-size: 32px !important;
  }

  .fs-mb-31 {
    font-size: 31px !important;
  }

  .fs-mb-30 {
    font-size: 30px !important;
  }

  .fs-mb-29 {
    font-size: 29px !important;
  }

  .fs-mb-28 {
    font-size: 28px !important;
  }

  .fs-mb-27 {
    font-size: 27px !important;
  }

  .fs-mb-26 {
    font-size: 26px !important;
  }

  .fs-mb-25 {
    font-size: 25px !important;
  }

  .fs-mb-24 {
    font-size: 24px !important;
  }

  .fs-mb-23 {
    font-size: 23px !important;
  }

  .fs-mb-22 {
    font-size: 22px !important;
  }

  .fs-mb-21 {
    font-size: 21px !important;
  }

  .fs-mb-20 {
    font-size: 20px !important;
  }

  .fs-mb-19 {
    font-size: 19px !important;
  }

  .fs-mb-18 {
    font-size: 18px !important;
  }

  .fs-mb-17 {
    font-size: 17px !important;
  }

  .fs-mb-16 {
    font-size: 16px !important;
  }

  .fs-mb-15 {
    font-size: 15px !important;
  }

  .fs-mb-14 {
    font-size: 14px !important;
  }

  .fs-mb-13 {
    font-size: 13px !important;
  }

  .fs-mb-12 {
    font-size: 12px !important;
  }

  .fs-mb-11 {
    font-size: 11px !important;
  }

  .fs-mb-10 {
    font-size: 10px !important;
  }

  .fs-mb-9 {
    font-size: 9px !important;
  }

  .fs-mb-8 {
    font-size: 8px !important;
  }

  .fs-mb-7 {
    font-size: 7px !important;
  }

  .fs-mb-6 {
    font-size: 6px !important;
  }

  .fs-mb-5 {
    font-size: 5px !important;
  }

  .fs-mb-4 {
    font-size: 4px !important;
  }

  .fs-mb-3 {
    font-size: 3px !important;
  }

  .fs-mb-2 {
    font-size: 2px !important;
  }

  .fs-mb-1 {
    font-size: 1px !important;
  }
}
